import { Avatar, IconButton, Menu, MenuItem } from "@mui/material";
import React from "react";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

const ProfileButton = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const { instance } = useMsal();
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleLogout(instance: IPublicClientApplication) {
    instance.logout({
      postLogoutRedirectUri: "https://curess.profitflow.dev/logout",
    });
  }

  return (
    <div>
      <IconButton
        color="inherit"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Avatar alt="Remy Sharp" style={{ width: 24, height: 24 }} />
      </IconButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={() => handleLogout(instance)}>Uitloggen</MenuItem>
      </Menu>
    </div>
  );
};

export default ProfileButton;
