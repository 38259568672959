import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import { SnackbarProvider } from "notistack";
import React, { Suspense, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Loading from "./components/Loading";
import AuthorizedApolloProvider from "./contexts/AuthorizedApolloProvider";
import { AuthContext } from "./contexts/AuthProvider";
import useDarkMode from "./hooks/useDarkMode";
import ReloadPrompt from "./ReloadPrompt";
import { msalConfig } from "./utils/MSALConfig";
import { dark, light } from "./utils/MuiTheme";
import Routes from "./utils/Routes";
const msalInstance = new PublicClientApplication(msalConfig);

function App() {
  const { isDarkMode } = useDarkMode();
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [role, setRole] = useState(localStorage.getItem("role"));
  LicenseInfo.setLicenseKey("5b59d736e8463f66040b50796797e111Tz00Mzk3NixFPTE2ODQ0ODMyMDU0OTksUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=");

  return (
    <BrowserRouter>
      <ThemeProvider theme={isDarkMode ? dark : light}>
        <SnackbarProvider maxSnack={3}>
          <CssBaseline />
          <AuthContext.Provider
            key={"authentication_provider"}
            value={{ token, setToken, role, setRole }}
          >
            <MsalProvider instance={msalInstance}>
              <AuthorizedApolloProvider token={token} role={role}>
                <Suspense fallback={<Loading />}>
                  <Routes />
                  <ReloadPrompt />
                </Suspense>
              </AuthorizedApolloProvider>
            </MsalProvider>
          </AuthContext.Provider>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
