import React from "react";

export interface IUser {
  id: string;
  name: string;
  email: string;
  team_id?: string;
  role: string;
}

export interface IToken {
  exp: number;
  iat: number;
  sub: string;
  first_name: string;
  last_name: string;
  email: string;
  "https://hasura.io/jwt/claims": {
    "x-hasura-user-id": string;
    "x-hasura-team-id": string;
    "x-hasura-allowed-roles": string[];
    "x-hasura-default-role": string;
  };
}

export interface IAuthContext {
  token: string | null;
  setToken: (token: string) => void;
  role: string | null;
  setRole: (token: string) => void;
}

export const AuthContext = React.createContext<IAuthContext>({
  token: "",
  setToken: (token: string) => {},
  role: "",
  setRole: (token: string) => {},
});
