import React from "react";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options: ChartOptions<'bar'> = {
    plugins: {
        title: {
            display: true,
            text: 'Per zorgproduct',
        },
    },
    locale: 'nl',
    scales: {
        x: {
            stacked: true,
        },
        y: {
            stacked: true,
        },
    },
};

const labelsMonthly = ['Januari', 'Februari', 'Maart', 'April', 'Mei', 'Juni', 'Juli', 'Augustus', 'September', 'Oktober', 'November', 'December'];
const labelsYearly = ['2022', '2023'];
const labelsQuarterly = ['Q1', 'Q2', 'Q3', 'Q4'];

export const data = {
    labels: labelsYearly,
    datasets: [
        {
            label: 'ASH',
            data: [1, 2],
            backgroundColor: '#aa71d5',
        },
        {
            label: 'ISB',
            data: [3, 4],
            backgroundColor: '#ffc000',
        },
        {
            label: 'GO',
            data: [1, 2],
            backgroundColor: '#00b0f0',
        },
        {
            label: 'Regulier',
            data: [1, 2],
            backgroundColor: 'grey',
        },
    ],
};

const timeOptions = [
    {
        label: 'Jaar',
        value: 'year'
    },
    {
        label: 'Kwartaal',
        value: 'quarter'
    },
    {
        label: 'Maand',
        value: 'month'
    },
];

const yearOptions = [
    {
        label: '2022',
        value: 2022
    },
    {
        label: '2023',
        value: 2023
    },
]

export type DashboardCareProductBarChartProps = {
    time: string;
    year: number;
}
export const DashboardCareProductBarChart = (props: DashboardCareProductBarChartProps) => {
    if (props.time === 'year') data.labels = labelsYearly;
    if (props.time === 'month') data.labels = labelsMonthly;
    if (props.time === 'quarter') data.labels = labelsQuarterly;
    return <Bar options={options} data={data} />
}