import React from "react";
import { Navigate } from "react-router";
import { useMsal } from "@azure/msal-react";

const roleMap: any = {
  "9bc72520-4586-4bef-b0fd-10e292b9e192": "planner",
  "352157d6-4b30-459f-b90e-cd9e87ebcf36": "planner",
  "5db049d6-84a8-4fbd-9aee-2001bf617b26": "team_coordinator",
  "da45c5e5-454e-409b-a37e-8c6d43beb03c": "team_coordinator",
  "a464ea24-01fc-43ec-a659-6027f67aaa44": "hulpverlener",
  "9457bebf-1680-43c4-9d8a-97ade9bed615": "hulpverlener",
  "be3fa6fd-2cc7-4f4f-b542-0de5c56cc7f1": "read_only",
};

export const MSALGuard = ({
  children,
  roles,
}: {
  children: JSX.Element;
  roles: string[];
}) => {
  const { instance } = useMsal();
  const accounts = instance.getAllAccounts();

  const account = accounts[0];
  const hasRole = ((account?.idTokenClaims as any)?.groups as string[])?.some(
    (el) => roles.includes(roleMap[el])
  );

  if (!account) {
    return <Navigate to={"/login"} />;
  }

  if (account && !hasRole) {
    return <Navigate to={"/"} />;
  }
  return children;
};
