import {
  ApolloClient,
  ApolloProvider,
  from,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { HttpLink } from "apollo-link-http";
import { getUnixTime } from "date-fns";
import React from "react";
import { useNavigate } from "react-router";
import { loginRequest } from "../utils/MSALConfig";

interface Definition {
  kind: string;
  operation?: string;
}

const AuthorizedApolloProvider = ({ children, token, role }: any) => {
  // let uri: string = "https://curess-prod.hasura.app/v1/graphql"
  let uri: string = import.meta.env.VITE_GRAPHQL;
  let uriWS: string = import.meta.env.VITE_GRAPHQL_WS;
  const { instance } = useMsal();
  const [idToken, setIdToken] = React.useState("");
  let headers: any = {};
  if (role) {
    headers["X-Hasura-Role"] = role;
  }

  const navigate = useNavigate();
  if (idToken) {
    headers["authorization"] = idToken;
  }

  const httpLink = new HttpLink({
    uri,
    headers,
  });

  // // Create a WebSocket link:
  // const wsLink = new WebSocketLink({
  //   uri: uriWS, // use wss for a secure endpoint
  //   options: {
  //     reconnect: true,
  //     connectionParams: {
  //       headers,
  //     },
  //   },
  // });
  const RefreshTokenLink = setContext(async (operation, { headers }) => {
    const keys = Object.keys(sessionStorage).filter(
      (x) => x.indexOf("accesstoken") > 0
    );
    const token = sessionStorage.getItem(keys[0]);
    try {
      if (token) {
        let exp = localStorage.getItem("exp");
        if (exp) {
          const iat = Number(exp);
          const now = getUnixTime(new Date());

          if (iat <= now) {
            return instance
              .ssoSilent(loginRequest)
              .then((res) => {
                setIdToken(`Bearer ${res.idToken}`);
                localStorage.setItem("exp", (res.idTokenClaims as any).exp);
                return {
                  headers: {
                    ...headers,
                    authorization: `Bearer ${res.idToken}`,
                  },
                };
              })
              .catch(async (error) => {
                if (error instanceof InteractionRequiredAuthError) {
                  navigate("/login");
                }
              });
          }
        }
      }
    } catch (e) { }

    return instance
      .acquireTokenSilent(loginRequest)
      .then((res) => {
        setIdToken(`Bearer ${res.idToken}`);
        localStorage.setItem("exp", (res.idTokenClaims as any).exp);

        return {
          headers: { ...headers, authorization: `Bearer ${res.idToken}` },
        };
      })
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          navigate("/login");
        }
      });
  });

  // using the ability to split links, you can send data to each link
  // depending on what kind of operation is being sent
  // const link: ApolloLink = split(
  //   // split based on operation type
  //   ({ query }) => {
  //     const { kind, operation }: Definition = getMainDefinition(query);
  //     return kind === "OperationDefinition" && operation === "subscription";
  //   },
  //   // wsLink,
  //   httpLink
  // );

  const apolloClient = new ApolloClient({
    link: from([RefreshTokenLink as any, httpLink] as any) as any,

    cache: new InMemoryCache(),
    connectToDevTools: true,
  });

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

export default AuthorizedApolloProvider;
