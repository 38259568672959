import React from "react";
import { LinearProgress } from "@mui/material";
import { withStyles, createStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import { Fade } from "@mui/material";

const BorderLinearProgress = withStyles((theme: any) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: theme.palette.primary.main,
    },
  })
)(LinearProgress);

const Loading = ({ loading }: { loading?: boolean }) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        gap: 60,
        height: "100%",
        paddingTop: "10vw",
      }}
    >
      <Fade
        in={loading}
        style={{
          transitionDelay: loading ? "500ms" : "0ms",
        }}
        unmountOnExit
      >
        <BorderLinearProgress color="primary" style={{ width: "50%" }} />
      </Fade>
    </div>
  );
};

export default Loading;
