import { MenuItem, Stack, TextField, Box } from "@mui/material";
import React, { useState } from "react";
import useResponsive from "../hooks/useResponsive";
import { Bar } from 'react-chartjs-2';
import { DashboardCareProductBarChart } from "../components/DashboardCareProductBarChart";
import { DashboardTeamBarChart } from "../components/DashboardTeamsBarChart";

const timeOptions = [
    {
        label: 'Jaar',
        value: 'year'
    },
    {
        label: 'Kwartaal',
        value: 'quarter'
    },
    {
        label: 'Maand',
        value: 'month'
    },
];

const yearOptions = [
    {
        label: '2022',
        value: 2022
    },
    {
        label: '2023',
        value: 2023
    },
]

const Dashboard = () => {
    const isDesktop = useResponsive('up', 'md');
    const [time, setTime] = useState('year');
    const [year, setYear] = useState(2022);
    return (
        <Stack gap={1} maxHeight="100vh" width={1}>
            <Stack gap={1}>
                <TextField size="small" fullWidth value={time} select onChange={(e) => {
                    setTime(e.target.value);
                }}>
                    {timeOptions.map(option => <MenuItem key={option.value} value={option.value} children={option.label} />)}
                </TextField>
                {time === 'quarter' && (
                    <TextField size="small" fullWidth value={year} select onChange={(e) => {
                        setYear(parseInt(e.target.value));
                    }}>
                        {yearOptions.map(option => <MenuItem key={option.value} value={option.value} children={option.label} />)}
                    </TextField>
                )}
            </Stack>
            <Stack direction="row" gap={1}>
                <Box flex={1} height={'70vh'}>
                    <DashboardCareProductBarChart time={time} year={year} />
                </Box>
                <Box flex={1} height={'70vh'}>
                    <DashboardTeamBarChart time={time} year={year} />
                </Box>

            </Stack>
        </Stack>
    );
};

export default Dashboard;
