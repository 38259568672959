import { PopupRequest, Configuration } from "@azure/msal-browser";

export const msalConfig: Configuration = {
  auth: {
    clientId: "c37abadf-cd7b-49fa-a8a5-26e7528a9979",
    authority:
      "https://login.microsoftonline.com/f248d05c-19ec-42e3-8b10-164e299430c4", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    redirectUri: "https://curess.profitflow.dev/azure/redirect",
    // redirectUri: "http://localhost:3000/azure/redirect",

    // extraQueryParameter: "prompt=admin_consent",
  },

  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest: PopupRequest = {
  scopes: ["User.Read", "User.Read.All", "offline_access", "openid", "profile"],
};

export const refreshRequest: PopupRequest = {
  // extraQueryParameters: { prompt: "admin_conset" },
  scopes: ["c37abadf-cd7b-49fa-a8a5-26e7528a9979"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
