import {
  Toolbar,
  Divider,
  Badge,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Drawer,
  Typography,
  Tooltip,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled, useTheme } from "@mui/material/styles";
import { Offline, Online } from "react-detect-offline";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import React from "react";
import {
  DateRange,
  ManageAccounts,
  Groups,
  Event,
  Filter9Plus,
  Wifi,
  WifiOff,
  Dashboard,
  Queue,
} from "@mui/icons-material";
import BadgeIcon from "@mui/icons-material/Badge";
import CuressDark from "../assets/logo-dark.svg";
import CuressLight from "../assets/logo-white.svg";
import NotificationsPopOver from "./NotificationsPopOver";
import SettingsDrawer from "./SettingsDrawer";
import useDarkMode from "../hooks/useDarkMode";
import ProfileButton from "./ProfileButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";

const drawerWidth = 195;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Layout = (props: { window: any }) => {
  const theme = useTheme();
  const { isDarkMode } = useDarkMode();
  const location = useLocation();
  const user_role = localStorage.getItem("role_pretty");
  const { accounts, instance } = useMsal();
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <div>
      <Toolbar />
      <Divider />
      <List>
        {(user_role == "admin" ||
          user_role == "planner" ||
          user_role == "read_only" ||
          user_role == "team_coordinator") && (
            <ListItem
              selected={location.pathname.includes("/teams")}
              button
              onClick={() => navigate("/teams")}
            >
              <ListItemIcon>
                <Groups
                  color={
                    location.pathname.includes("/teams") ? "primary" : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Teams" />
            </ListItem>
          )}
        {(user_role == "admin" || user_role == "hulpverlener") && (
          <ListItem
            selected={location.pathname.includes("/caseload")}
            button
            onClick={() => navigate("/caseload")}
          >
            <ListItemIcon>
              <DateRange
                color={
                  location.pathname.includes("/caseload")
                    ? "primary"
                    : "inherit"
                }
              />
            </ListItemIcon>
            <ListItemText primary="Mijn caseload" />
          </ListItem>
        )}
        {(user_role == "admin" || user_role == "planner" || user_role == "read_only") && (
          <>
            <ListItem
              selected={location.pathname.includes("/allteams")}
              button
              onClick={() => navigate("/allteams")}
            >
              <ListItemIcon>
                <Filter9Plus
                  color={
                    location.pathname.includes("/allteams")
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Alle caseloads" />
            </ListItem>
            <ListItem
              selected={location.pathname.includes("/clienten")}
              button
              onClick={() => navigate("/clienten")}
            >
              <ListItemIcon>
                <BadgeIcon
                  color={
                    location.pathname.includes("/clienten")
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Cliënten" />
            </ListItem>
            <ListItem
              selected={location.pathname.includes("/gebruikers")}
              button
              onClick={() => navigate("/gebruikers")}
            >
              <ListItemIcon>
                <ManageAccounts
                  color={
                    location.pathname.includes("/gebruikers")
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Gebruikers" />
            </ListItem>
            <ListItem
              selected={location.pathname.includes("/caseloadruimte")}
              button
              onClick={() => navigate("/caseloadruimte")}
            >
              <ListItemIcon>
                <Event
                  color={
                    location.pathname.includes("/caseloadruimte")
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Caseload ruimte" />
            </ListItem>
            <ListItem
              selected={location.pathname.includes("/wachtlijst")}
              button
              onClick={() => navigate("/wachtlijst")}
            >
              <ListItemIcon>
                <Queue
                  color={
                    location.pathname.includes("/wachtlijst")
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Wachtlijst" />
            </ListItem>
            <ListItem
              selected={location.pathname.includes("/dashboard")}
              button
              onClick={() => navigate("/dashboard")}
            >
              <ListItemIcon>
                <Dashboard
                  color={
                    location.pathname.includes("/dashboard")
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </>
        )}
      </List>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    // <AuthenticatedTemplate>
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <img src={isDarkMode ? CuressDark : CuressLight} height={20} alt="" />
          <Typography variant="h5" sx={{marginLeft: 'auto'}}>TEST OMGEVING</Typography>
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              gap: 10,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Online>
              <Tooltip arrow title="Online!">
                <IconButton color="inherit">
                  <Wifi />
                </IconButton>
              </Tooltip>
            </Online>
            <Offline>
              <Tooltip arrow title="Offline!">
                <IconButton color="inherit">
                  <Badge
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    badgeContent={"!"}
                    color="error"
                  >
                    <WifiOff />
                  </Badge>
                </IconButton>
              </Tooltip>
            </Offline>
            <NotificationsPopOver />
            <SettingsDrawer />

            <ProfileButton />
          </div>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="folders"
      >
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          overflow: "hidden",
        }}
      >
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  window: PropTypes.func,
};

export default Layout;
