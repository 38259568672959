import React, { lazy } from "react";
import {
  Navigate,
  Outlet,
  useRoutes,
} from "react-router-dom";
const Login = lazy(() => import("../pages/Login"));
const Logout = lazy(() => import("../pages/Logout"));
const CaseloadRoom = lazy(() => import("../pages/CaseloadRoom"));
const Users = lazy(() => import("../pages/Users"));
const Teams = lazy(() => import("../pages/Teams"));
const Team = lazy(() => import("../pages/Team"));
const Clients = lazy(() => import("../pages/Clients"));
const Caseload = lazy(() => import("../pages/Caseload"));
const WaitingList = lazy(() => import("../pages/WaitingList"));
const AllTeams = lazy(() => import("../pages/AllTeams"));
const CaseLoadReview = lazy(() => import("../components/CaseLoadReview"));
import Layout from "../components/Layout";
import Dashboard from "../pages/Dashboard";
import { MSALGuard } from "../utils/MSALGuard";
const Routes = () => {
  let routes = useRoutes([
    {
      path: "/",
      element: (
        <MSALGuard roles={["planner", "team_coordinator", "hulpverlener", "read_only"]}>
          <Layout />
        </MSALGuard>
      ),
      children: [
        {
          path: "/teams",
          element: (
            <MSALGuard roles={["planner", "team_coordinator", "read_only"]}>
              <Teams />
            </MSALGuard>
          ),
          children: [
            {
              path: ":id",
              element: <Outlet />,
              children: [
                {
                  path: "",
                  element: <Team />,
                },
                {
                  path: "review",
                  element: (
                    <MSALGuard roles={["team_coordinator"]}>
                      <CaseLoadReview />
                    </MSALGuard>
                  ),
                },
              ],
            },
          ],
        },
        {
          path: "/teams/:id",
          element: (
            <MSALGuard roles={["planner", "team_coordinator", "read_only"]}>
              <Teams />
            </MSALGuard>
          ),
        },
        {
          path: "/allteams",
          element: (
            <MSALGuard roles={["hulpverlener", "planner", "read_only"]}>
              <AllTeams />
            </MSALGuard>
          ),
        },
        {
          path: "/caseload",
          element: (
            <MSALGuard roles={["hulpverlener"]}>
              <Caseload />
            </MSALGuard>
          ),
        },
        {
          path: "/clienten",
          element: (
            <MSALGuard roles={["planner", "read_only"]}>
              <Clients />
            </MSALGuard>
          ),
        },
        {
          path: "/gebruikers",
          element: (
            <MSALGuard roles={["planner", "read_only"]}>
              <Users />
            </MSALGuard>
          ),
        },
        {
          path: "/caseloadruimte",
          element: (
            <MSALGuard roles={["planner", "read_only"]}>
              <CaseloadRoom />
            </MSALGuard>
          ),
        },
        {
          path: "/wachtlijst",
          element: (
            <MSALGuard roles={["planner"]}>
              <WaitingList />
            </MSALGuard>
          ),
        },
        {
          path: "/dashboard",
          element: (
            <MSALGuard roles={["planner"]}>
              <Dashboard />
            </MSALGuard>
          ),
        },
      ],
    },
    // {
    //   path: "/login",
    //   element: <Login />,
    // },
    // {
    //   path: "/forget-password",
    //   element: <ForgetPassword />,
    // },
    // {
    //   path: "/password-reset/:user_id/:token",
    //   element: <PasswordReset />,
    // },
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "logout",
      element: <Logout />,
    },
    {
      path: "*",
      element: <Navigate to={"/"} />,
    },
  ]);
  return routes;
};

export default Routes;
